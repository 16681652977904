<script lang="ts" setup>
import Checkbox from '~/components/form-elements/Checkbox.vue'
import LabelText from '~/components/form-elements/LabelText.vue'
import type { CheckboxChoice } from '~/types/form-elements'

const modelValue = defineModel<any>()

const props = withDefaults(
  defineProps<{
    choices: CheckboxChoice[]
    isVertical?: boolean
    labelText?: string | null
    name: string
  }>(),
  { isVertical: false, labelText: null }
)

const emit = defineEmits<{
  (e: 'onCheckboxChanged', event: any): void
}>()

function onCheckboxChanged (event: any): void {
  emit('onCheckboxChanged', event)
}
</script>

<template>
  <div>
    <LabelText class="mb-2" :value="labelText" />

    <div class="checkbox-area-wrapper mb-2.5 flex" :class="{ horizontal: !isVertical, vertical: isVertical }">
      <Checkbox
        v-for="x in choices"
        :key="String(x.value)"
        v-model="modelValue"
        :input-value="x.value"
        :is-order-tag="x.isOrderTag"
        :is-status="x.isStatus"
        :label-text="x.labelText"
        :name="name"
        :order-tag-color="x.orderTagColor"
        :status-color="x.statusColor"
        @on-checkbox-changed="onCheckboxChanged"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.checkbox-area-wrapper {
  &.horizontal {
    align-items: center;
    flex-wrap: wrap;

    .checkbox-area {
      margin-bottom: 10px;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.vertical {
    align-items: flex-start;
    flex-direction: column;

    .checkbox-area {
      margin-bottom: 10px;
      margin-right: auto;
    }
  }
}
</style>
